@import "../../../styles/variable.module.scss";
.top_sellers {
  padding-bottom: 24px;
  .h3 {
    span {
      margin-right: 8px;
      & + span {
        width: 26px !important;
        height: 26px !important;
      }
    }
  }
  .selectMenu {
    width: 256px;
    @include large_device {
      width: 195px;
    }
    @include mobile_device {
      width: 100%;
      margin-top: 20px;
    }
  }
  .topSellers {
    margin-top: 18px;
    position: relative;
    @include medium_device {
      margin-top: 0;
    }
    [class*="swiper-slide"] {
      padding-bottom: 40px;
      @include medium_device{
        display: flex;
        height: auto;
        [class$=grid-column-1]{
          width: 100%;
        }
      }
    }
    .card {
      overflow: hidden;
      cursor: pointer;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      @include medium_device{
        flex: 1 1 auto;
      }
      .cardBody {
        padding: 0 15px 15px;
        min-height: 39px;
        background-color: var(--light-bg);
        @include medium_device{
          padding: 0 10px 10px;
          min-height: inherit;
        }
      }
      .sellerImg {
        position: relative;
        background-color: var(--light-bg);
        border-radius: 8px 8px 0 0;
        img {
          border-radius: 8px 8px 0 0;
        }
        .id {
          position: absolute;
          width: 32px;
          height: 32px;
          border-radius: 24px;
          color: $lightColor;
          left: 14px;
          top: 14px;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          font-weight: 600;
          background-color: $customFont;
        }
      }
      &:hover {
        -webkit-box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
        box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
      }
    }
    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: var(--desc-font);
      margin-bottom: 0;
      padding: 15px;
      background-color: var(--light-bg);
      @include medium_device{
        font-size: 14px;
        padding: 10px;
      }
      & > span {
        margin-right: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        & + span {
          width: 16px !important;
          height: 16px !important;
          flex: 0 0 auto;
          @include medium_device{
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
    }
    .eth {
      font-size: 12px;
      line-height: 20px;
     
      span {
        color: var(--smallFont);
        font-weight: 600;
        margin-right: 5px;
      }
    }
    .sellerDetails {
      .socialLink {
        &:not(:last-child) {
          margin-right: 15px;
          @include medium_device{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .top_sellers {
    .h3 {
      span {
        margin-left: 8px;
        margin-right: 0;
      }
    }
    .sellerDetails {
      .socialLink {
        &:not(:last-child) {
          margin-right: 0;
          margin-left: 15px;
          @include medium_device{
            margin-left: 10px;
          }
        }
      }
    }
    .topSellers {
      .name {
        & > span {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      .card {
        .sellerImg {
          .id {
            right: 14px;
            left: auto;
          }
        }
      }
    }
    .selectMenu {
      @include mobile_device {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}
